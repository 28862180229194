<template>
  <div>
    <div class="list">
      <div class="cont" >
        <div class="unit" v-for="(item,index ) in pageProps.records" :key="index" 
        >
          <img :src=item.logo /> 
          
          <div>
            <p>{{ item.title }}</p>
            <p> {{ item.description }}</p>
           
          </div>
        </div>
      </div>
     

    </div>

    <div style="text-align:center;padding:20px">
        <!--分页-->
        <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageProps.total"
            :page-size="pageProps.pageSize"
            :page-sizes="[5, 10, 50, 100]"
            :current-page="pageProps.pageNum"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
      </div>  
    
  </div>
</template>

<script>
import { projectJobList } from "@/api/project/index";

export default {
  data() {
    return {
      searchLoading: false,
        pageProps: {
          records:[],
          total: 0,
          pageSize: 10,
          pageNum: 1
        },

        searchFormData:{
          name:"",
          page:0,
          limit:0
        },
    };
  },
  mounted() {
    var project_id= this.$route.query.project_id;
    if(!project_id){
      project_id=localStorage.getItem("project_id");
    }
      this.searchFormData.project_id=project_id;
      this.fetchData()
    },
    
  methods: {

    fetchData(){
        var that = this;
        that.searchFormData.page=that.pageProps.pageNum;
        that.searchFormData.limit=that.pageProps.pageSize;

        projectJobList(that.searchFormData).then((response) => {
          console.log(response);
          if(response.code==200){
            that.pageProps.records = response.data.data;
            that.pageProps.total = response.data.total;
          }else{
            that.$message.error(response.msg?response.msg:"请求错误");
          }
         
        });
      },

       // 当前页变更
      handleCurrentChange(num) {
        this.pageProps.pageNum = num
        this.fetchData()
      },


      // 每页显示页面数变更
      handleSizeChange(size) {
        this.pageProps.pageSize = size
        this.fetchData()
      },
      back(){
      this.$router.go(-1);
    },

  

      formatDateTime(value) { // 时间戳转换日期格式方法
        if (value == null) {
          return ''
        } else {
          const date = new Date(value)
          const y = date.getFullYear() // 年
          let MM = date.getMonth() + 1 // 月
          MM = MM < 10 ? ('0' + MM) : MM
          let d = date.getDate() // 日
          d = d < 10 ? ('0' + d) : d
          let h = date.getHours() // 时
          h = h < 10 ? ('0' + h) : h
          let m = date.getMinutes()// 分
          m = m < 10 ? ('0' + m) : m
          let s = date.getSeconds()// 秒
          s = s < 10 ? ('0' + s) : s
          return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
          }
      }
  },
};
</script>

<style lang="scss" scoped>
.cont {
  display: flex;
  flex-wrap: wrap;
}
.unit{
  padding: 20px;
  margin: 10px;
  display: flex;
  align-items: center;
  width: 40%;
  border-radius: 3px;
  background-color: #f6f6f6;
}
.list {
  padding: 20px;
  background-color: white;
}
img {
  width: 90px;
  height: 68px;
  margin-right: 30px;
  border-radius: 5px;
}
</style>
